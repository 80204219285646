div[class^='styles_contentArea'] {
  margin-top: -60px;
}

div[class^='styles_contentArea'] > div[class^='navbar'] a span:after {
  display: none;
}

.ngap-navbar div[class^='asideContainer'],
.ngap-navbar div[class^='innerNav'],
.ngap-navbar div[class^='innerNav'] a {
  height: 48px;
}

.ngap-navbar a[class^='logo'] {
  min-width: 260px;
}

.ngap-navbar a[class^='logo'] span {
  font-size: 22px !important;
}

.ngap-navbar div[class^='innerNav'] a {
  height: 100%;
  padding: 0 15px;
  display: flex;
  align-items: center;
}

.ngap-navbar div[class^='innerNav'] a span {
  padding: 5px 0;
}

.ngap-navbar div[class^='innerNav'] a span:after {
  bottom: 0 !important;
}

.nav-spacer {
  height: 60px;
}

.nav-spacer + #console-integrated-platform .ngap-navbar {
  display: none;
}
